import React, { useState, useEffect } from 'react'
import BasicSelect from './BasicSelect'
import { LangOb } from '../static/Languages'
import Routing from './Routing'
import Home from './Home'
import ResponsiveAppBar from './ResponsiveAppBar'
import Cookies from 'universal-cookie'
import Box from '@mui/material/Box'
import { Roots } from '../static/Root'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import Grid from '@mui/material/Grid'
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';

let Langu = 'en'
export function LangF() {
  return Langu
}

export default function Main() {
  const cookies = new Cookies()
  const [lang, setLang] = React.useState('en')

  const handleChange = (event) => {
    setLang(event.target.value)
    Langu = event.target.value
    cookies.set('lang', Langu, { path: '/' })
  }

  useEffect(() => {
    if (cookies.get('lang') !== undefined) {
      Langu = cookies.get('lang')
      setLang(Langu)
    }
  }, Langu)

  function headerFooterCross() {
    return (
      <div
        style={{
          backgroundColor: '',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Grid container style={{ padding: '1%' }}>
          {/* Logo */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '3%',
              }}
            >
              <img src={Roots().Logo} width="50%" />
            </div>
          </Grid>
          {/* Call */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '3%',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <div>
                
                
              <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                {/* hide on screens wider than md */}
                {/* Mobile */}
                <Button
                  style={{ zoom: '1.5' }}
                  size="large"
                  variant="outlined"
                  startIcon={<LocalPhoneIcon />}
                >
                  (226) 748-3580
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                {/* hide on screens smaller than md */}
                {/* Wide screen */}
                <Button
                  style={{ zoom: '2' }}
                  size="large"
                  variant="outlined"
                  startIcon={<LocalPhoneIcon />}
                >
                  (226) 748-3580
                </Button>
              </Box>



              </div>
              <div style={{ color: 'black', padding: '1% 0' }}>
                <h1>KWC Bin Rentals</h1>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  
  function afterHeader() {
    return (
      <div
        style={{
          backgroundColor: 'rgb(10 25 42)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          padding: '1%',
          color: 'white'
        }}
      >
        <Grid container style={{ padding: '1%' }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
            <h1 style={{width: '80%', textAlign: 'center'}}>Residential and Commercial Bin Rentals</h1>
              <p 
              style={{
                  fontSize: '20px',
                width: '80%',
              }}>
                Rent a bin in Waterloo Region. CuBins offers bin rental
                and roll-off dumpster service to complete your various projects.
                CuBins rents bins for home renovations, construction debris,
                garage cleanup as well as other junk and trash removal jobs in
                the Waterloo Region. We guarantee your satisfaction with every
                bin that we deliver, so order today with our quick and easy
                ordering process, and have your trash gone tomorrow!
              </p>

              <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                {/* hide on screens wider than md */}
                {/* Mobile */}
                <Button
                style={{ zoom: '1.2' }}
                size="large"
                variant="outlined"
                startIcon={<PhoneForwardedIcon />}
              >
                GET A FREE QUOTE
              </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                {/* hide on screens smaller than md */}
                {/* Wide screen */}
                <Button
                style={{ zoom: '2' }}
                size="large"
                variant="outlined"
                startIcon={<PhoneForwardedIcon />}
              >
                GET A FREE QUOTE
              </Button>
              </Box>

     
              <h2>Rent a bin today!</h2>

            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  function readyToRent() {
    return (
      <div
        style={{
          backgroundColor: 'rgb(10 25 42)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          padding: '1%',
          color: 'white'
        }}
      >
        <Grid container style={{ padding: '1%' }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <h1>Ready to Rent?</h1>
              <h2>Let's do this</h2>
              
       
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div style={{ height: '100%' }}>
      <header>
        {headerFooterCross()}
        {afterHeader()}
      </header>
      <div style={{ height: '100%' }}>
        <Home />
      </div>
      <footer className="Main-footer">
      </footer>
      {readyToRent()}
      {headerFooterCross()}
    </div>
  )
}
