import * as React from 'react';
import ActionAreaCard from './ActionAreaCard';
import { Roots } from '../static/Root';
import { LangOb } from '../static/Languages';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function Home() {
    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "row" }}>
            <Grid container style={{ padding: "1%" }} >

            {/* Bins: 5 - 7 - 10 - 14 - 20 Yards */}

                {/* Medal */}
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "3%", height: 400 }}>
                        <ActionAreaCard url="/medal" img={Roots().Blue} title={"5 Yard Dumpster"} detail={"- Dimensions: 12' x 7' x 2' - Equals Roughly 50 Trash Bags"} />
                    </div>
                </Grid>

                {/* Message */}
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "3%", height: 400 }}>
                        <ActionAreaCard url="/message" img={Roots().Blue} title={"7 Yard Dumpster"} detail={"- Dimensions: 12' x 8' x 2' - Equals Roughly 70 Trash Bags"}  />
                    </div>
                </Grid>

                {/* Jesus */}
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "3%", height: 400 }}>
                        <ActionAreaCard url="/jesus" img={Roots().Blue} title={"10 Yard Dumpster"} detail={"- Dimensions: 10' x 7' x 4' - Equals Roughly 100 Trash Bags"} />
                    </div>
                </Grid>

                {/* Saints */}
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "3%", height: 400 }}>
                        <ActionAreaCard url="/saints" img={Roots().Blue} title={"14 Yard Dumpster"} detail={"- Dimensions: 11' x 8' x 4.5' - Equals Roughly 140 Trash Bags"} />
                    </div>
                </Grid>

                {/* Antichrist quiz */}
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "3%", height: 400 }}>
                        <ActionAreaCard url="/quiz" img={Roots().Blue} title={"20 Yard Dumpster"} detail={"- Dimensions: 12' x 8' x 6' - Equals Roughly 200 Trash Bags"} />
                    </div>
                </Grid>

            </Grid>
        </div>
    );
}