// General Timeline Start
import Logo from "../images/Logo.png"

import Timeless from "../images/what-was-god-doing-before-creation-updated.jpg"

import BC6000 from "../images/29994-adam-and-eve-in-the-garden-of-eden.800w.tn.jpg"
import BC5700 from "../images/1024px-Edward_Hicks,_American_-_Noah's_Ark_-_Google_Art_Project.jpg"
import BC1900 from "../images/1102009454_univ_lsr_xl.jpg"
import BC1850 from "../images/43995-26389-sacrficeofisaac-abraham-isaac-angel-ram.1200w.tn_.800w.tn_.jpg"
import BC1800 from "../images/251e16bea3b4dbc47766f4e9a5a99610.jpg"

import BC1750 from "../images/2015327_univ_cnt_2_xl.jpg"
import BC1250 from "../images/cph_30256235761.jpg"
import BC1000 from "../images/samuel_anoints_david.jpg"
import BC550 from "../images/Tissot_The_Flight_of_the_Prisoners.jpg"

import AC0 from "../images/adoration_of_the_shepherds_cc_img.jpg"
import AC33 from "../images/Christ_at_the_Cross_-_Cristo_en_la_Cruz.jpg"
import AC37 from "../images/1200px-La_conversion_de_Saint_Paul_Giordano_Nancy_3018.jpg"
import AC40 from "../images/Virgen_del_Pilar_sin_manto.jpg"
import AC331 from "../images/Codex_Vaticanus_end_or_Luke.jpg"

import AC405 from "../images/Jacques_Blanchard_-_Hl._Hieronymus.jpg"
import AC480 from "../images/Melk16.jpg"
import AC1182 from "../images/Philip_Fruytiers_-_St._Francis_of_Assisi.jpg"
import AC1194 from "../images/ChiaraAssisCos.jpg"
import AC1415 from "../images/St Benedict Medal.jpg"

import AC1830 from "../images/prayer-to-our-lady-of-the-miraculous-medal.jpg"

import AC1887 from "../images/P-pio.jpg"
import AC1905 from "../images/817HEQEQGbL.jpg"
import AC1917 from "../images/D0000103.jpg"
import AC1961 from "../images/Garabandal_Visionaries_Ecstasies_.jpg"
import AC1981 from "../images/hqdefault.jpg"
// General Timeline End

// Saints Timeline Starts
import AC230 from "../images/800px-thumbnail.jpg"
import AC1070 from "../images/El_milagro_del_pozo._Alonso_Cano_(1638-1640).jpg"

// Saints Timeline Ends

// Quiz 
import Anti from "../images/Detail-The-Deeds-of-Antichrist-Luca-Signorelli-1505.jpg"
// Verse
import Verse from "../images/get-more-from-the-bible-this-year-riqkc7bg-a6841d3f40168705fd31c6259caf6d92.jpg"
//Header
import FishWhite from "../images/fish white.png"
import CrossWhite from "../images/CrossWhite.png"

import Message from "../images/El_mensaje_del_profeta_Ezequiel_para_hoy_1054_630_80.jpg"
import Blue from "../images/large bin blue.jpg"

export function Roots() {
    let r =
    {
        Logo,
        //General Timeline
        Timeless, BC6000, BC5700, BC1900, BC1850, BC1800, BC1750, BC1250, BC1000, BC550, AC0, AC33, AC37, AC40, AC331, AC405, AC480, AC1182, AC1194, AC1415, AC1830, AC1887, AC1905, AC1917, AC1961, AC1981,
        //Saints Timeline
        AC230, AC1070,
        //Quiz
        Anti,
        // Verse
        Verse,
        //Header
        FishWhite, CrossWhite,
        Message,
        Blue,
    };
    return r;
}